var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"report-details-wrapper balance-sheet",class:_vm.isLoading ? 'is-loading-wrapper' : '',attrs:{"outlined":""}},[(_vm.isLoading)?_c('div',{staticClass:"loading-wrapper"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#0171a1","indeterminate":""}})],1):_vm._e(),(!_vm.isLoading)?_c('div',[_c('div',{staticClass:"report-main-header"},[_c('v-breadcrumbs',{staticClass:"pl-0 px-3 pb-4 px-sm-0 pt-0",attrs:{"items":_vm.breadCrumb},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"link":"","href":item.href,"disabled":item.disabled},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(item.href)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,1461327762)}),_c('div',{staticClass:"d-md-flex d-sm-flex justify-space-between align-center report-details-header"},[_c('div',{staticClass:"px-3 px-sm-0"},[_c('span',{staticClass:"report-title text-h5"},[_c('h2',{staticClass:"report-title"},[_vm._v("Balance Sheet")])])])])],1),_c('v-sheet',[_c('report-search',{attrs:{"filters":_vm.filters},on:{"toggle":_vm.onToggleSearch}})],1),_c('v-card',{attrs:{"flat":"","loading":_vm.isLoading}},[_c('v-row',[(_vm.reportsData)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-data-table',{staticClass:"balance-sheet-table-wrapper elevation-0",attrs:{"headers":_vm.headers,"items":_vm.reportsData.tableBody,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"hide-default-footer":"","items-per-page":300,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.header",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"header-name font-semi-bold"},[_vm._v(_vm._s(item.header))])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-semi-bold"},[_vm._v(_vm._s(item.totalAmount))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"balance-sheet-table-content elevation-0",attrs:{"headers":_vm.headersSub,"mobile-breakpoint":"769","items":item.body,"hide-default-footer":"","items-per-page":300,"fixed-header":"","show-expand":"","single-expand":false,"item-key":"id"},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"balance-sheet-table-inner-content elevation-0",attrs:{"headers":_vm.headersSub2,"mobile-breakpoint":"769","items":item.sub_rows,"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.account_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#6D858F"}},[_vm._v(_vm._s(item.account_name))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.trans_de_balance ? item.trans_de_balance : item.balance)+" ")])]}}],null,true)})],1)]}}],null,true)})],1)]}}],null,true)})],1):_vm._e(),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}})],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }